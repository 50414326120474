.c-card {
  &-large {
    &__title {
      text-align: center;
      @include mq-max(xs) {
        font-size: 18px;
      }
    }
    &__image {
      margin-bottom: 50px;
      @include mq-max(xs) {
        margin-bottom: 25px;
      }
    }
    &__btnArea {
      text-align: right;
    }
    &__text {
      margin-bottom: 40px;
      @include mq-max(xs) {
        margin-bottom: 0;
      }
    }
  }

  &-thin {
    max-width: 250px;
    width: 100%;
    @include mq-max(xs) {
      max-width: 100%;
      display: flex;
    }
    &__image {
      box-shadow: 0px 1px 16px rgba(0,0,0,.16);
      margin-bottom: 30px;
      @include mq-max(xs) {
        margin-bottom: 0;
      }
    }
    &__textArea {
      padding-left: 10px;
      padding-top: 10px;
      & > p {
        @include mq-max(xs) {
          font-size: 14px;
        }
      }
    }
    &__title {
      font-size: 16px;
      margin-bottom: 10px;
      @include mq-max(sm) {
        font-size: 15px;
      }

    }
  }
}