
.c-box {
  background: $white;
  border: 1px solid $gray;
  border-radius: $border-radius;
  padding: 30px 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0
  }

  &--bgColor {
    border: none;
    background: $bgColor;
  }

  &--white {
    border: none;
  }

  &--pL {
    padding: 45px 60px;
    @include mq-max(sm) {
      padding: 30px 15px;
    }
  }
}