.l-navigation {
//  background-color: $white;
  // height: 100%;
  display: none;
  height: auto;
  padding-bottom: 90px;
  background-color: rgba(255,255,255,.9);
  @include mq-max($changePoint) {
    padding-bottom: 0;
    background-color: transparent;
    box-shadow: none;
  }

  //ハンバーガーメニューオープン時に追加されるクラス
  &.is-navOpen {
    box-shadow: 0px 3px 16px -5px rgba(0,0,0,.16);
  }

  &__inner {
    padding: 0;
    display: block;
    height: 100%;//menu.jsで指定した値が優先される
    max-width: 400px;
    margin: 0 auto;
    @include mq-max($changePoint) {
      height: calc(100vh - #{$headerHeight-sp}); //画面高さ - fixedヘッダーの高さ
      overflow-y: scroll;
      max-width: 100%;
      margin: 0;
    }
  }
  &__item {
    display: block;
    height: auto;
    position: relative;
    align-items: center;
    margin-right: 0;
    // border-top: 1px solid $gray;
    text-align: center;
    @include mq-max($changePoint) {
      text-align: left;
    }
    &:last-child {
      margin-right: 0;
      border-bottom: 1px solid $gray;
    }
    a {
      @include mq-max($changePoint) {
        background-color: $white;
      }
    }
    &>a {
      text-decoration: none;
      color: $textColor;
      font-weight: 600;
      // display: inline-block;
      // display: flex;
      // align-items: center;
      // height: 100%;
      padding: 25px 10px;
      display: block;
      @include mq-max($changePoint) {
        font-size: 15px;
        padding: 25px 30px;
        position: relative;
      }
      @media (any-hover: hover) {
        &:hover {
          color: $keyColor;
        }
      }

      &::after {
        @include fa('f105');
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    //お問い合わせメニュー
    &--contact {
      & > a {
        background-color: $black;
        color: $white;
        &::after {
          content: none;
        }
        @include mq-max($changePoint) {
          text-align: center;
        }
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a {
        &::after {
          @include fa('f067');
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
        }
        &.has-dropdown {
          &::after {
            content: '\f068';
          }
        }
      }

      .l-dropDown {
        position: relative;
        top: auto;
        overflow: hidden;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          left: 0;
        }
        @include mq-max($changePoint) {
        }
        li {
          @include mq-max($changePoint) {
          }
          &:last-child {
          }
          a {
            background-color: $white;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;
            line-height: 0;
            height: 0;
            opacity: 0;
            transition: height $transition,
                        opacity 0.4s,
                        padding $transition,
                        line-height $transition;
            @include mq-max($changePoint) {

            }
          }
        }
      }

      // クリック時
      &.isDropdownOpen {
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          top: auto;
          opacity: 1;
          display: block;
          @include mq-max($changePoint) {

          }
          &>li {
            border-bottom: 1px solid $gray;
            &:first-child {
              border-top: 1px solid $gray;
            }
            &:last-child {
              border-bottom: none;
            }
          }
          a {
            transition: color $transition,
                        height $transition;
                        opacity: 1;
                        line-height: 1.5;
                        height: 54px;
                        padding: 15px 10px 15px 25px;
                        overflow: hidden;
            @media (any-hover: hover) {
              &:hover {
                color: $keyColor;
              }
            }
          }
        }
      }

    }
  }
}
