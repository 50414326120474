//波の設定
.c-wave {
  // height: 90px !important;
  position: relative;
  height: 100px;
  left: 0;
  overflow: hidden;
  @include mq-max(x-lg) {
    // height: 150px;
  }
  @include mq-max($changePoint) {
    // height: 120px;
  }
  @include mq-max(sm) {
    height: 90px;
  }
  @include mq-max(xs) {
    height: 60px;
  }
  &__inner {
    position: absolute;
    bottom: 0;
    width: 100%;

    &--roted {
      top: 0;
      bottom: auto;
      transform: rotate(180deg);
    }
  }
}