.l-header {
  // background-color: $white;
  position: relative;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: $headerHeight;
    margin: 0 auto;
    // padding: 0 30px;
    position: relative;
    z-index: 100;
    position: fixed;
    top: 0;
    background: transparent;
    @include mq-max($changePoint) {
      // display: block;
      height: $headerHeight-sp;
      // padding: 0;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    // margin-right: 20px;
    line-height: 0;
    display: inline-block;
    width: 200px; // 状況によって変更可
    @include mq-max($changePoint) {
      // margin: 0;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    @media (any-hover: hover) {
      &:hover {
        img {
          opacity: .7;
        }
      }
    }

    //文字が入っているのでその設定
    & > span {
      font-size: 13px;
      color: $gray;
    }
  }

  &__modules {
    position: relative;
    line-height: 0;
    height: 100%;
    padding: 10px;
    padding-left: 50px;
    line-height: 0;
    display: flex;
    align-items: center;
    background-color: $white;
    transition: all $transition;
    @include mq-max($changePoint) {
      padding-left: 30px;
    }
    @include mq-max(sm) {
      background-color: $white;
    }
    @include mq-max(xs) {
      padding-left: 15px;
    }
    .open & {
      background-color: rgba(255,255,255,.9);
      @include mq-max($changePoint) {
        background-color: $white;
      }
    }
  }



  &__wave {
    // position: absolute;
    // top: 0;
    // width: 100%;
    // height: 100px;
    @include mq-max($changePoint) {
      // top: 0;
    }
    .c-wave {
      height: 150px;
      @include mq-max(bp1920) {
        height: 140px;
      }
      @include mq-max(md) {
        height: 120px;
      }
      @include mq-max(xs) {
        height: 90px;
      }
      &__inner {
        transition: all .5s ease;
        left: -2px;
        width: 101%;
        .open & {
          // bottom: $headerHeight*2;
          opacity: 0;
        }
      }
    }
  }
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 42px;
  height: 42px;
  cursor: pointer;
  z-index: 3;
  span {
    display: block;
    position: absolute;
    width: 30px;
    border-bottom: solid 2px #626262;
    transition: .35s ease-in-out; //変化の速度を指定
    left: 6px;
    &:nth-child(1) {
      top: 9px;
    }
    &:nth-child(2) {
      top: 18px;
    }
    &:nth-child(3) {
      top: 27px;
    }
  }
  &.is-toggleActive {
    span{
      &:nth-child(1) {
        top: 18px;
        left: 6px;
        transform: rotate(-45deg);
      }
      &:nth-child(2),&:nth-child(3) {
        top: 18px;
        transform: rotate(45deg);
      }
    }
  }
  @include mq-max($changePoint) {

  }
}
