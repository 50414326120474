.l-f {
  background-color:$white;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 100px;
    display: flex;
    flex-direction: column;
    @include mq-max(content) {
      padding: 0 20px 50px;
    }
    @include mq-max(xs) {
      flex-wrap: wrap;
      margin-bottom: -30px;
    }
  }
  &__info {
    display: block;
    & > p {
      font-size: 15px;
    }
  }
  &__logo {
    max-width: 187px;
    display: inline-block;
    transition: opacity $transition !important;
    margin-bottom: 10px;
    margin-left: -15px;
    @include mq-max(xs) {
      margin-left: 0;
    }
    @media (any-hover: hover) {
      &:hover {
        opacity: $hover-opacity;
      }
    }
    > img {
      width: auto;
    }
  }
  &__nav {
    display: flex;
    justify-content: end;
    margin-top: 30px;
    @include mq-max(sm) {
      justify-content: center;
    }
    @include mq-max(xs) {
      flex-wrap: wrap;
      justify-content: start;
    }
  }
  &__links {
      margin-left: 90px;
      @include mq-max(md) {
        margin-left: 50px;
      }
      @include mq-max(xs) {
        margin-left: 0;
        margin-bottom: 30px;
        width: 50%;
      }
      &:first-child {
        margin-left: 0;
      }
      & > li {
        margin-bottom: 10px;
        a {
          display: block;
          padding: 2px 0;
          font-weight: 600;
          font-size: 15px;
          color: $textColor;
          @media (any-hover: hover) {
            &:hover {
              color: $keyColor;
            }
          }
        }
      }
    }

  &__bottom {
    max-width: $contentWidth;
    margin: 0 auto 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq-max(content) {
      padding: 0 20px;
    }
    @include mq-max(xs) {
      flex-direction: column;
    }
    &-link {
      font-size: 11px;
      color: $textColor !important;
      @include mq-max(xs) {
        margin-bottom: 20px;
      }
      @media (any-hover: hover) {
        &:hover {
          color: $keyColor !important;
        }
      }
    }

  }

  //Copyright
  &__copyright {
    text-align: center;
    display: block;
    // color: $white;
    // background-color: $black;
    // padding: 10px 0;
  }
}
