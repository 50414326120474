//スライダーの設定を読み込み(不要なら記述削除)
@import "cvSlider";

.c-cvArea {
  position: relative;
  background: url('/inc/image/common/cvArea/bg_cvArea.png');
  background-repeat: no-repeat;
  background-size: cover;

  &__title {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-family: $serif;
    font-weight: 400;
    @include mq-max(xs) {
      font-size: 23px;
      margin-bottom: 30px;
    }
    &--white {
      color: $white;
    }
  }

  &__inner {
    padding: 100px 20px 70px;
    position: relative;

    @include mq-max(sm) {
      padding-top: 60px;
    }
    // .is-IE & {
    //   //背景画像を設定する場合jpgを記載
    //   // background: url('');
    // }
    &__textArea {
      max-width: $contentWidth;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }

    //背景画像にフィルターを付ける設定
    &--filter {
      &::after {
        content: '';
        opacity: .7;
        background-color: #051224;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
  }

  //ボタン設定
  &__buttonArea {
    position: relative;
    z-index: 1;
    margin: 0 auto 30px;
  }
  &__button {
    width: 100%;
    height: 100%;
    padding: 28px 45px 28px 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    @include mq-max(xs) {
      padding: 28px 35px 28px 26px;
    }
  }

  &__tel {
    text-align: center;
    margin-bottom: 40px;
  }

  &__info {
    text-align: center;
  }

  &__wave {
    // position: absolute;
    // width: 100%;
    // bottom: 0;

  }
}