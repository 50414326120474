///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding: 18px 45px 18px 26px;
  width: auto;
  color: $textColor;
  background-color: #fff;
  line-height: 1.25;
  border: 2px solid $keyColor;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    @include fa('f054');
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: right $transition;
    line-height: 1;
  }
  @media (any-hover: hover) {
    &:hover {
      color: $white;
      background-color: $black;
      &::after {
        // right: 10px;
      }
    }
  }


  //PDF
  &[href$=".pdf"] {
    @media (any-hover: hover) {
      &:hover {
        &::after {
          right: 16px;
          color: $white;
        }
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &::after {
      color: $keyColor;
    }
    @media (any-hover: hover) {
      &:hover {
        &::after {
          right: 16px;
          color: $white;
        }
      }
    }
  }


  //Modifier
  //小さいボタン
  &--small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //反転
  &--reverse {
    color: $white;
    background-color: $keyColor;
    @media (any-hover: hover) {
      &:hover {
        color: $keyColor;
        background-color: $white;
      }
    }
  }
}

.c-underLinelink {
  color: $textColor !important;
  text-decoration: underline;
  text-underline-offset: 7px;
  font-size: 14px;
  font-weight: 600;
  @media (any-hover: hover) {
    &:hover {
      color: $keyColor !important;
    }
  }
}

//オリジナルボタン
//ホバーすると波が出る
.c-waveBtn {
  padding: 23px 45px 23px 23px;
  display: inline-block;
  font-weight: 600;
  position: relative;
  max-width: 334px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  transition: all $transition !important;
  @include mq-max(xs) {
    padding: 23px 35px 23px 23px;
    font-size: 14px;
    text-align: right;
  }
  // transition-delay: .4s !important;
  &:hover {
    // background-color: rgba(219, 230, 219, 1);
    color: $keyColor !important;
    &::before {
      left: -25%;
      top: -60%;
      // height: 500px;
      height: 560%;
    }
  }
  & > span {
    position: relative;
    z-index: 1;
  }
  &::before {
    content: '';
    background-image: url('/inc/image/common/bg_btnHover.svg');
    width: 130%;
    height: 120%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 100%;
    display: inline-block;
    transition: all .5s ease;
  }
  &::after {
    content: '';
    height: 1px;
    width: 45px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-color: $keyColor;
    @include mq-max(xs) {
      width: 25px;
    }
  }
}

.c-hasImageBtn {
  background-color: $white;
  display: inline-block;
  box-shadow: 0px 1px 3px rgba(0,0,0,.16);
  position: relative;
  top: 0;
  transition: all $transition !important;
  @media (any-hover: hover) {
    &:hover {
      top: -6px;
      box-shadow: 0px 1px 6px rgba(0,0,0,.16);
      .c-hasImageBtn {
        &__date {
          color: $keyColor;
        }
        &__text {
          color: $keyColor;
        }
        &__image {
          opacity: $hover-opacity;
        }
      }
    }
  }

  &__info {
    padding: 17px 20px 30px;
  }
  &__date {
    font-family: $roboto;
    color: $gray;
    font-size: 15px;
    margin-bottom: 10px;
    transition: all $transition;
  }
  &__image {
    transition: all $transition;
    opacity: 1;
  }
  &__text {
    color: $textColor;
    transition: all $transition;
  }
}

.c-cvIconBtn {
  display: inline-block;
  max-width: 250px;
  width: 100%;
  padding: 15px;
  border: 1px solid $white;
  background-color: rgba(0, 0, 0, .3);
  transition: all $transition !important;
  text-align: center;
  position: relative;
  top: 0;
  @media (any-hover: hover) {
    &:hover {
      top: -5px;
    }
  }
  & > span {
    display: inline-block;
    padding-left: 45px;
    color: $white;
    font-weight: 600;
    position: relative;
    & > img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}