///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //////////////////////////////////////////////////////////////////////////////////
  //メイン見出し（マクロ化済）
  //////////////////////////////////////////////////////////////////////////////////
  &-main {
    font-size: 30px;
    font-weight: 400;
    font-family: $serif;
    margin-bottom: 20px;
    letter-spacing: .09em;
    line-height: 1.6;
    @include mq-max(xs) {
      font-size: 25px;
    }
    // &__sub {}

    &--hasWave {
      margin-bottom: 30px;
      & > span {
        padding-top: 10px;
        background-color: $white;
        display: block;
        text-align: center;
        @include mq-max(sm) {
          padding-top: 0;
        }
      }
    }

    &--mbLarge {
      margin-bottom: 40px;
    }
  }

  //////////////////////////////////////////////////////////////////////////////////
  //大き目の見出し
  //////////////////////////////////////////////////////////////////////////////////
  &-large {
    font-size: 43px;
    font-weight: 400;
    font-family: $serif;
    margin-bottom: 20px;
    letter-spacing: .09em;
    line-height: 1.6;
    @include mq-max(lg) {
      font-size: 35px;
    }
    @include mq-max(xs) {
      font-size: 25px;
    }

    &--hasBorder {
      padding-right: 70px;
      position: relative;
      display: inline-block;
      @include mq-max(content) {
        padding-right: 23px;
      }
      &::after {
        content: '';
        height: 60%;
        width: 1px;
        background-color: $black;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }


  //////////////////////////////////////////////////////////////////////////////////
  //中見出し
  //////////////////////////////////////////////////////////////////////////////////
  &-middle {}



  //////////////////////////////////////////////////////////////////////////////////
  //小見出し
  //////////////////////////////////////////////////////////////////////////////////
  &-small {}



  //////////////////////////////////////////////////////////////////////////////////
  //全体Modifier
  //////////////////////////////////////////////////////////////////////////////////
  //真ん中寄せ
  &--center {
    text-align: center;
  }

  //白文字
  &--white {
    color: $white;
  }

}